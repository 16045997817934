<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div class="col toolbar-title">
          账单明细
          <div class="buttonbc">
            <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
              <img src="@/assets/back.png" alt="">
            </el-button>
          </div>
        </div>
        <div style="width: 100%;margin-top:10px">
          <p class="title_nav">
            <span>站点名称：{{ shopInfo.shop_name }}</span>
            <span>账单年月：{{ detail.sn | sn_filter }}</span>
            <span>商品总金额：{{ detail.goods_total }}</span>
            <span>运费总金额：{{ detail.shop_freight_price }}</span>
            <span>开票状态：{{ detail.invoice_state | status_filter }}</span>
          </p>
        </div>
        <el-tabs style="width: 100%;" v-model="params.account_statement_type" type="card" @tab-click="GET_AccountList">
          <el-tab-pane label="订单明细" name="1"></el-tab-pane>
          <el-tab-pane label="运费明细" name="2"></el-tab-pane>
        </el-tabs>
        <el-button style="position: absolute;right:0;top:83px" @click="exportOrder" size="small"
          type="primary">导出账单</el-button>
      </template>

      <template v-if="params.account_statement_type == '1'" slot="table-columns">
        <el-table-column label="关联总订单号" prop="trade_sn" />
        <el-table-column label="子订单号" prop="order_sn" />
        <el-table-column key="state1" label="订单状态" prop="trade_state">
          <template slot-scope="{row}">
            <span>{{ order_formate(row.trade_state) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出账时间" key="create_date" prop="create_date" width="160">
          <template slot-scope="{row}">{{ row.create_date | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="商品名称" prop="goods_name" />
        <el-table-column label="数量" prop="goods_num" />
        <el-table-column label="单价" prop="goods_price" />
        <el-table-column label="小计" prop="goods_total" />
      </template>
      <template v-else slot="table-columns">
        <el-table-column label="关联总订单号" prop="trade_sn" />
        <el-table-column key="state2" label="订单状态">
          <template slot-scope="{row}">
            {{ order_formate(row.trade_state) }}
          </template>
        </el-table-column>
        <el-table-column label="出账时间" key="create_date2" prop="create_date" width="160">
          <template slot-scope="{row}">{{ row.create_date | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="运费金额" prop="shop_freight_price" />
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as reconciliationInvoices from "@/api/reconciliationInvoices";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import { handleDownload_sheets } from "@/utils";
import { Foundation } from "@/../ui-utils";
export default {
  name: 'rechargeRecords',
  components: { EnTableLayout },
  data () {
    return {
      detail: {},
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        account_statement_type: '1',
        account_statement_sn: this.$route.query.sn || ''
      },
      /** 列表数据 */
      tableData: {},
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  filters: {
    /**开票状态 */
    status_filter: value => {
      switch (value) {
        case 0:
          return '未开票';
        case 1:
          return '开票中';
        default:
          return '已开票';
      }
    },
    sn_filter: value => {
      if (!value) return '';
      console.log(value)
      let str = value?.split('')
      str.splice(4, 0, '年')
      let newValue = str.join('')
      newValue = newValue + '月'
      return newValue
    }
  },
  mounted () {
    this.GET_AccountList();
    this.GET_detail();
  },
  methods: {
    order_formate (value) {
      switch (value) {
        case 'ALL':
          return '全部';
        case 'CONFIRM':
          return '待付款';
        case 'PAID_OFF':
          return '待发货';
        case 'SHIPPED':
          return '待收货';
        case 'COMPLETE':
          return '已完成';
        case 'CANCEL_IN':
          return '取消中';
        default:
          return '已取消';
      }
    },
    GET_detail () {
      const params = {
        account_statement_sn: this.$route.query.sn
      }
      reconciliationInvoices.getAccountStatementTotal(params).then(response => {
        this.loading = false;
        this.detail = response;
      });
    },
    /** 搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_AccountList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = {
        ...this.params
      }
      reconciliationInvoices.getAccountStatement(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    },
    /**导出账单 */
    async exportOrder () {
      this.importLoading = true;
      let tHeaderSheets = [
        [
          "关联总订单号",
          "子订单号",
          "订单状态",
          "出账时间",
          "商品名称",
          "数量",
          "单价",
          "小计",
        ],
        [
          "关联总订单号",
          "订单状态",
          "出账时间",
          "运费金额"
        ]
      ]
      let filterValSheets = [
        [
          "trade_sn",
          "order_sn",
          "trade_state",
          "create_date",
          "goods_name",
          "goods_num",
          "goods_price",
          "goods_total",
        ],
        [
          "trade_sn",
          "trade_state",
          "create_date",
          "shop_freight_price"
        ]
      ]
      let dataSheets = []
      const params1 = {
        page_no: 1,
        page_size: 999999,
        account_statement_type: '1',
        account_statement_sn: this.params.account_statement_sn
      }
      dataSheets.push(await reconciliationInvoices.getAccountStatement(params1).then(response => {
        response.data.forEach(item => {
          item.trade_state = this.order_formate(item.trade_state)
          item.create_date = Foundation.unixToDate(item.create_date);
        })
        return response.data
      }))
      const params2 = {
        page_no: 1,
        page_size: 999999,
        account_statement_type: '2',
        account_statement_sn: this.params.account_statement_sn
      }
      const data2 = await reconciliationInvoices.getAccountStatement(params2).then(response => {
        this.loading = false;
        response.data.forEach(item => {
          item.trade_state = this.order_formate(item.trade_state)
          item.create_date = Foundation.unixToDate(item.create_date);
        })
        return response.data
      });
      dataSheets.push(data2)
      const sheetNameList = ['订单明细', '运费明细']
      handleDownload_sheets(dataSheets, tHeaderSheets, filterValSheets, sheetNameList, "");

    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

.txInfoFrom {
  .el-form-item {
    margin-bottom: 0;
  }
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.title_nav {
  span {
    margin-right: 80px;
  }
}

.buttonbc {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1A43A9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}
</style>
